import React, { useContext } from "react";
import "./Analyzer.css";

import SudokuContext from "../SudokuContext";
import { fullLogicSolve } from "../Solver/Scripts/additionalFunctions";
import { simple2fullgrid } from "../Creator/Scripts/simple2full";
import { handleStep } from "../Solver/Scripts/additionalFunctions";
import { SudokuStats } from "../Solver/Scripts/additionalFunctions";
import {
  initialCandidates,
  updateCandidates,
} from "../Solver/Scripts/updateCandidates";
import { getDependendGroups } from "../Solver/Scripts/dependendGroups";
import { string2grid } from "../GlobalScripts/string2grid";
import useGrid from "../Hooks/useGrid";
import { cloneDeep } from "lodash";

function Analyzer() {
  const { mode, setMode, grid, setGrid, selectionGrid, setting } =
    useContext(SudokuContext);
  const [newGrid, setNewGrid] = useGrid();

  const dependendGroups = getDependendGroups(grid);

  const stats = new SudokuStats();

  function analyzeSudoku() {
    const copyGrid = cloneDeep(grid);
    const solveResults = fullLogicSolve(copyGrid);
    console.log("SCORE:", solveResults.score);
  }

  function pasteSampleSudoku() {
    // UNGELÖST
    // const sample =
    //   "000000020000970006001020903694000000100600000502007000000050008400800100005703609";
    // HARD
    // const sample =
    //   "039200710000000000070000800105030000090600030080075900050006000901007400008900002";
    // MEDIUM
    // const sample =
    //   "008007500400100000010035904004670800200090100000004000000050000720803000100000005";
    // EASY
    // const sample =
    //   "040300290005006407690007080069705000700943002004200971182000509000009728000580060";
    // TEST
    const sample =
      "040100008008003125200908007600000000023069080050000700400000200000001000009007040";
    const pastedFullGrid = string2grid(sample, newGrid);
    setGrid(pastedFullGrid);
  }

  function analyzeStep() {
    let newGrid = cloneDeep(grid);
    // console.log(newGrid);
    // Beim ersten Mal die Candidates setzen
    for (const dependendGroup of dependendGroups) {
      // console.log(dependendGroup);
      for (const cell of dependendGroup) {
        if (cell.state === null && cell.candidates.length == 0) {
          newGrid = initialCandidates(newGrid);
          newGrid = updateCandidates(newGrid);
          setGrid(newGrid);
          return;
        }
      }
    }

    const stepResults = handleStep(newGrid, stats);
    stats.addScore(stepResults.score);
    if (stepResults.finished) {
      console.log(stats);
    }
    setGrid(stepResults.grid);
  }

  function setInitialCandidates() {
    let newGrid = cloneDeep(grid);
    newGrid = initialCandidates(newGrid);
    setGrid(newGrid);
  }

  return (
    <div className="Analyzer__container">
      <div className="Analyzer_StepAnalyzer">
        <button
          onClick={() => {
            setInitialCandidates();
          }}
        >
          Candidates
        </button>
        <button
          onClick={() => {
            analyzeStep();
          }}
        >
          Analyze Step
        </button>
      </div>

      <button
        onClick={() => {
          analyzeSudoku();
        }}
      >
        Analyze Full
      </button>
      <button
        onClick={() => {
          pasteSampleSudoku();
        }}
      >
        Paste Sample Sudoku
      </button>
    </div>
  );
}

export default Analyzer;
