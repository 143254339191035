import React from "react";
import { useState } from "react";
import { Cell } from "../Backend/Cell";

function useGrid() {
  // Initial Grid
  const initialGrid = Array(9)
    .fill(null)
    .map(() => Array(9).fill(null));
  for (let idx_col = 0; idx_col < 9; idx_col++) {
    for (let idx_row = 0; idx_row < 9; idx_row++) {
      initialGrid[idx_col][idx_row] = new Cell([idx_col, idx_row]);
    }
  }
  const [grid, setGrid] = useState(initialGrid);

  return [grid, setGrid];
}

export default useGrid;
