import { useEffect, useState, useContext } from "react";
import "./MainGrid.css";
import GridComp from "../GridComps/GridComp";
import SudokuContext from "../SudokuContext";
// import NumPadPopup from "../NumPad.js/NumPadPopup";

function MainGrid() {
  const { sudoku, setSudoku, sudokuRef } = useContext(SudokuContext);

  useEffect(() => {
    function handleMouseDown(event) {
      // Selektieren mit Mausklick
      // shift | ctrl verhindern reset des selectionGrid
      const validClick =
        (event.srcElement.id >= 0 &&
          event.srcElement.id <= 88 &&
          event.srcElement.id != "") ||
        event.srcElement.className === "ColorButton__container" ||
        event.srcElement.className === "NumPad_Digit" ||
        event.srcElement.parentElement.className === "NumPadBar" ||
        event.srcElement.parentElement.className === "NumPad_Grid_Delete";
      if (validClick) {
        event.preventDefault();
        // Handle reset condition
        let reset = true;
        if (event.shiftKey || event.ctrlKey) {
          reset = false;
        }
        handleSelection(event, reset);
        document.addEventListener("mousemove", handleMouseMove);
      } else {
        sudokuRef.current.resetSelection();
        setSudoku({ ...sudokuRef.current });
      }
    }

    function handleMouseMove(event) {
      // Selektion mit gedrückter Maustaste
      handleSelection(event, false);
    }

    function handleMouseUp() {
      document.removeEventListener("mousemove", handleMouseMove);
    }

    function handleSelection(event, reset) {
      // Ohne shift | ctrl wird es zuerst resettet
      const id = event.srcElement.id;

      if (id !== "" && Number(id) >= 0 && Number(id) <= 88) {
        // Klick war auf ein Feld im Sudoku
        const [idx_row, idx_col] = id.split("");
        if (reset) {
          // Zuerst Sudoku Selektion zurücksetzen, falls Resetbedingung aktiv
          sudokuRef.current.resetSelection();
        }
        if (!sudokuRef.current.grid[idx_row][idx_col].isSelected) {
          // Geklicktes Feld ist noch nicht selektiert
          sudokuRef.current.grid[idx_row][idx_col].isSelected = true;
          setSudoku({ ...sudokuRef.current });
        }
      }
    }

    let pressTimer;
    let isLongPress = false;
    function handleTouchStart(event) {
      // Starte den Timer für den Long Press
      pressTimer = setTimeout(() => {
        isLongPress = true;
        // Selektiert die neue Zelle ohne reset
        handleSelection(event, false);
      }, 150);
    }

    function handleTouchEnd(event) {
      // Stoppe den Timer
      clearTimeout(pressTimer);
      isLongPress = false;
    }

    function handleKeyDown(event) {
      // Zahleneingaben in Zellen schreiben für ausgewählte Zellen
      // shift -> corner number
      // ctrl -> center number
      if (!event.repeat) {
        // Bei langem Drücken der Taste wird die Funktion nur 1x durchgeführt
        event.preventDefault();
        const key = parseInt(event.code.charAt(event.code.length - 1));

        if (key >= 1 && key <= 9) {
          const shift = event.shiftKey;
          const ctrl = event.ctrlKey;
          // Corner Numbers
          if (shift === true) {
            for (let row = 0; row < 9; row++) {
              for (let col = 0; col < 9; col++) {
                if (sudokuRef.current.grid[row][col].isSelected) {
                  sudokuRef.current.grid[row][col].setCorner(
                    Number(key),
                    sudokuRef.current.isSetting
                  );
                }
              }
            }
            // Center Numbers
          } else if (ctrl === true) {
            for (let row = 0; row < 9; row++) {
              for (let col = 0; col < 9; col++) {
                if (sudokuRef.current.grid[row][col].isSelected) {
                  sudokuRef.current.grid[row][col].setCenter(
                    Number(key),
                    sudokuRef.current.isSetting
                  );
                }
              }
            }
          } else {
            // Value Number
            for (let row = 0; row < 9; row++) {
              for (let col = 0; col < 9; col++) {
                if (sudokuRef.current.grid[row][col].isSelected) {
                  sudokuRef.current.grid[row][col].setValue(
                    Number(key),
                    sudokuRef.current.isSetting
                  );
                  sudokuRef.current.full2simple();
                }
              }
            }
          }
        } else if (event.key == "Backspace" || event.key == "Delete") {
          // Einträge löschen mit Del | Backspace
          for (let row = 0; row < 9; row++) {
            for (let col = 0; col < 9; col++) {
              if (sudokuRef.current.grid[row][col].isSelected) {
                sudokuRef.current.grid[row][col].clearAll(
                  sudokuRef.current.isSetting
                );
              }
            }
          }
        }
        sudokuRef.current.full2simple();
        setSudoku({ ...sudokuRef.current });
      }
    }
    console.log("MOUNT");
    document.addEventListener("mousedown", handleMouseDown);
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("touchstart", handleTouchStart);
    document.addEventListener("touchend", handleTouchEnd);
    document.addEventListener("touchmove", handleTouchEnd);

    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("touchstart", handleTouchStart);
      document.removeEventListener("touchend", handleTouchEnd);
      document.removeEventListener("touchmove", handleTouchEnd);
    };
  }, []);

  // Popup Numpad
  const [isToggled, setIsToggled] = useState(false);
  function handleContextMenu(event) {
    // event.preventDefault();
    setIsToggled(true);
  }

  return (
    <div className="MainGrid__container">
      <GridComp sudoku={sudoku} />
      {/* {isToggled ? <NumPadPopup /> : ""} */}
      {/* <button
        onClick={() => {
          console.log("from button: ", sudoku);
        }}
      >
        print sudoku
      </button> */}
    </div>
  );
}

export default MainGrid;
