import React, { useEffect, useState } from "react";
import "./CreatorPage.css";

import AutoCreator from "../../Sudoku_Components/Creator/AutoCreator";
import { cloneDeep } from "lodash";

function CreatorPage() {
  const [sudokuStats, setSudokuStats] = useState({
    easy: 0,
    medium: 0,
    hard: 0,
  });

  // SQL FUNCTION
  const getSQLInfo = async () => {
    await fetch("/api/countSudokus.php")
      .then((response) => response.json())
      .then((data) => {
        console.log("JSON RESPONSE:", data);
        let tempSudokuStats = { easy: null, medium: null, hard: null };
        for (const difficultyGroup of data) {
          if (difficultyGroup.Schwierigkeitsgrad === "easy") {
            tempSudokuStats.easy = parseInt(difficultyGroup.Anzahl);
          } else if (difficultyGroup.Schwierigkeitsgrad === "medium") {
            tempSudokuStats.medium = parseInt(difficultyGroup.Anzahl);
          } else {
            tempSudokuStats.hard = parseInt(difficultyGroup.Anzahl);
          }
        }
        setSudokuStats(tempSudokuStats);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    getSQLInfo();
  }, []);

  function DatabaseDataFrame() {
    return (
      <div>
        <h3>Anzahl an Sudokus in Datenbank</h3>
        <div>Easy: {sudokuStats.easy}</div>
        <div>Medium: {sudokuStats.medium}</div>
        <div>Hard: {sudokuStats.hard}</div>
      </div>
    );
  }

  function SudokuGeneratorFrame() {
    return (
      <div>
        <h3>Sudoku Generator</h3>
        <AutoCreator />
      </div>
    );
  }

  return (
    <div>
      <DatabaseDataFrame />
      <SudokuGeneratorFrame />
    </div>
  );
}

export default CreatorPage;
