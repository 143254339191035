import { getDependendGroups } from "./dependendGroups";

export function getDirectPointing(grid) {
  // Pointing Pair ist ein Paar in einer Box die in eine
  // Zeile oder Spalte zeigt.
  // Eliminiert die Zahl des PPs aus der jeweiligen row oder col

  const dependenGroups = getDependendGroups(grid);
  let flag = false;

  //   Die letzten 9 Einträge sind Boxes
  const dependendBoxes = dependenGroups.slice(-9);

  //   Zellen überprüfen, ob eine Zahl nur in zwei Zellen möglich ist

  for (const box of dependendBoxes) {
    // Alle Boxes iterieren
    for (let number = 1; number <= 9; number++) {
      // Nummern von 1-9 betrachten
      let candidateCounter = 0; // zählt wie oft ein candidate in der Box vorkommt
      let bufferedCandidates = []; // speichert die zellen, die die zahl als candidate enthalten
      //   Anzahl der aktuellen Nummer zählen, falls = 2 ist es ein Pair
      for (let i = 0; i < box.length; i++) {
        if (box[i].candidates.includes(number)) {
          candidateCounter++;
          bufferedCandidates.push(box[i]);
        }
      }
      if (candidateCounter === 2) {
        // Pair gefunden. Index überprüfen, ob das Pair in eine Richtung zeigt
        const cell1 = bufferedCandidates[0];
        const cell2 = bufferedCandidates[1];

        // Richtung in Zeile überprüfen
        if (cell1.index[0] === cell2.index[0]) {
          flag = false;
          for (let col = 0; col < 9; col++) {
            if (col === cell1.index[1] || col === cell2.index[1]) {
              // Zellen in denen das Pair liegt werden übersprungen
              continue;
            }
            let tempFlag = grid[cell1.index[0]][col].removeCandidates([number]);

            if (tempFlag) {
              // Echtes PP in Zeile gefunden
              flag = true;
            }
          }
          // PP hat Candidates entfernt
          if (flag) {
            return [grid, true, 1.7];
          }
        }

        // Richtung in Spalte überprüfen
        if (cell1.index[1] === cell2.index[1]) {
          flag = false;
          for (let row = 0; row < 9; row++) {
            if (row === cell1.index[0] || row === cell2.index[0]) {
              continue;
            }
            let tempFlag = grid[row][cell1.index[1]].removeCandidates([number]);

            if (tempFlag) {
              // Echtes PP in Spalte gefunden
              flag = true;
            }
          }
          // PP hat Candidates entfernt
          if (flag) {
            return [grid, true, 1.7];
          }
        }
      }
    }
  }
  return [grid, false, 0];
}
