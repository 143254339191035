import React from "react";
import "./PresetPage.css";
import { Helmet } from "react-helmet";
import NumPadPopup from "../../Sudoku_Components/NumPad.js/NumPadPopup";

import Sudoku_Preset from "../../Sudoku_Components/Sudoku_Apps/Sudoku_Preset";

function PresetPage() {
  return (
    <div className="PresetPage__container">
      <MetaTags />
      <div className="PresetPage_SudokuApp">
        <Sudoku_Preset />
      </div>
      <div>
        <h1>About the Sudokus</h1>
        <span>
          The Sudoku puzzles on this site are generated using my proprietary
          generator, ensuring a unique and diverse set of challenges. Difficulty
          levels are modeled after puzzles from the NY Times, making them
          especially appealing to fans of those puzzles. If you enjoy NY Times
          Sudoku, you're sure to appreciate the quality of puzzles offered by
          Sudoku-Hub. Best of all, all our Sudokus are completely free, so feel
          free to give them a try. Wishing you success and, of course, lots of
          fun!
        </span>
        <h1>How to Play</h1>
        <span>
          Explore diverse candidate possibilities with our features – 'Number,'
          'Corner,' and 'Center.' Utilize 'Number' confidently for digits you
          are certain belong in a specific cell. Opt for 'Corner' (hold Shift)
          to place candidates in the corners of cells, allowing a maximum of 4
          candidates per cell. Alternatively, choose 'Center' (hold Ctrl) to
          position candidates in the middle of cells, accommodating up to 9
          candidates per cell. Effortlessly set common candidates across
          multiple cells by holding the mouse button or using shift/ctrl. For a
          personalized touch, use the color palette to individually shade cells
          as desired.
        </span>
        <h1>Basic Sudoku rules</h1>
        <span>
          Sudoku is a classic number puzzle game played on a 9x9 grid. The
          objective is to fill each row, column, and 3x3 subgrid with the
          numbers 1 to 9, ensuring that no repetition occurs within any row,
          column, or subgrid. The puzzle starts with some numbers already filled
          in, acting as clues. The challenge lies in logically deducing and
          strategically placing the remaining numbers to complete the grid. Keep
          in mind that every row, column, and subgrid must contain all digits
          from 1 to 9. Sudoku is a game of logic and deduction, offering a
          rewarding experience for players of all skill levels.
        </span>
      </div>
    </div>
  );
}

function MetaTags() {
  return (
    <Helmet>
      <title>Play Sudoku for free!</title>
      <meta
        name="description"
        content="Explore the world of Sudoku on our user-friendly website, offering free and accessible gameplay for all levels. 
  Enjoy a wide range of puzzles, from easy to challenging, and enhance your logical reasoning skills. Immerse yourself in 
  a hassle-free gaming experience, track your progress, and indulge in the timeless pleasure of Sudoku. Start playing for free today!"
      />
    </Helmet>
  );
}

export default PresetPage;
