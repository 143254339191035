import React, { useState, useEffect } from "react";
import clock_icon from "../../Images/uhr.png";

function Stopwatchcounter(props) {
  const [counter, setCounter] = useState(0);

  let isRunning = true;

  useEffect(() => {
    let interval;

    if (props.counterReset) {
      clearInterval(interval);
      setCounter(0);
      props.setCounterReset(false);
    }

    interval = setInterval(() => {
      setCounter((prevCounter) => prevCounter + 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [props.counterReset]);

  return (
    <div>
      {Math.floor(counter / 60)}:{counter % 60 < 10 ? "0" : ""}
      {counter % 60}
    </div>
  );
}

export default Stopwatchcounter;
