import React, { useContext } from "react";
import "./Plugin_Solver.css";
import SudokuContext from "../SudokuContext";
import { Solver } from "../Backend/Solver";
import { cloneDeep } from "lodash";

function Plugin_Solver() {
  const { grid, setGrid } = useContext(SudokuContext);
  const { sudoku, setSudoku } = useContext(SudokuContext);

  const handleBacktrackClick = () => {
    const newSudoku_ = cloneDeep(sudoku);
    newSudoku_.grid = grid;
    newSudoku_.full2simple();
    const solver = new Solver(newSudoku_);
    solver.backtrackSolve();

    setSudoku(newSudoku_);
    setGrid(sudoku.grid);
  };

  // const simpleGrid = Array(9)
  //   .fill(null)
  //   .map(() => Array(9).fill(0));

  // for (let row = 0; row < 9; row++) {
  //   for (let col = 0; col < 9; col++) {
  //     let gridState = grid[row][col].state;
  //     if (gridState != null) {
  //       simpleGrid[row][col] = gridState;
  //     }
  //   }
  // }

  // function handleClick() {
  //   solveSudoku(simpleGrid);
  //   const newGrid = cloneDeep(grid);
  //   for (let row = 0; row < 9; row++) {
  //     for (let col = 0; col < 9; col++) {
  //       const gridState = newGrid[row][col];
  //       if (gridState != null) {
  //         newGrid[row][col].state = simpleGrid[row][col];
  //       }
  //     }
  //   }
  //   setGrid(newGrid);
  // }

  // function solveSudoku(board) {
  //   const emptyCell = findEmptyCell(board);

  //   // Wenn es keine leeren Zellen mehr gibt, ist das Sudoku gelöst.
  //   if (!emptyCell) {
  //     return true;
  //   }

  //   const [row, col] = emptyCell;

  //   // Versuche Zahlen von 1 bis 9 in das leere Feld einzusetzen.
  //   for (let num = 1; num <= 9; num++) {
  //     if (isValidMove(board, row, col, num)) {
  //       // Setze die Zahl ein, wenn sie gültig ist.
  //       board[row][col] = num;

  //       // Versuche das Sudoku mit der eingesetzten Zahl zu lösen.
  //       if (solveSudoku(board)) {
  //         return true;
  //       }

  //       // Falls das Sudoku mit der eingesetzten Zahl nicht gelöst werden kann,
  //       // setze das Feld zurück und probiere eine andere Zahl.
  //       board[row][col] = 0;
  //     }
  //   }

  //   // Falls keine Zahl eingesetzt werden kann, muss ein früherer Schritt zurückgehen.
  //   return false;
  // }
  // function findEmptyCell(board) {
  //   for (let row = 0; row < 9; row++) {
  //     for (let col = 0; col < 9; col++) {
  //       if (board[row][col] === 0) {
  //         return [row, col];
  //       }
  //     }
  //   }
  //   return null;
  // }
  // function isValidMove(board, row, col, num) {
  //   // Überprüfe Zeile und Spalte auf Konflikte.
  //   for (let i = 0; i < 9; i++) {
  //     if (board[row][i] === num || board[i][col] === num) {
  //       return false;
  //     }
  //   }

  //   // Berechne den Startpunkt des 3x3-Unterquadrats.
  //   const startRow = Math.floor(row / 3) * 3;
  //   const startCol = Math.floor(col / 3) * 3;

  //   // Überprüfe das 3x3-Unterquadrat auf Konflikte.
  //   for (let i = 0; i < 3; i++) {
  //     for (let j = 0; j < 3; j++) {
  //       if (board[startRow + i][startCol + j] === num) {
  //         return false;
  //       }
  //     }
  //   }

  //   // Wenn keine Konflikte gefunden wurden, ist der Zug gültig.
  //   return true;
  // }

  return (
    <div className="Solver__container">
      <button onClick={handleBacktrackClick}>Backtrack Solve</button>
    </div>
  );
}

export default Plugin_Solver;
