import React, { useContext } from "react";
import "./ColorPalette.css";
import SudokuContext from "../SudokuContext";

function ColorPalette() {
  const { setSudoku, sudokuRef } = useContext(SudokuContext);

  function ColorButton(props) {
    const { color } = props;
    const style = { backgroundColor: color };

    function setColor() {
      for (let row = 0; row < 9; row++) {
        for (let col = 0; col < 9; col++) {
          if (sudokuRef.current.grid[row][col].isSelected) {
            sudokuRef.current.grid[row][col].setColor(color);
          }
        }
      }
      setSudoku({ ...sudokuRef.current });
    }

    return (
      <div
        className="ColorButton__container"
        style={style}
        onClick={() => {
          setColor();
        }}
      ></div>
    );
  }
  return (
    <div className="ColorPalette__container">
      <ColorButton color="rgba(255,255,255,1)" />
      <ColorButton color="rgba(230,230,230,1)" />
      <ColorButton color="rgba(30,30,30,1)" />
      <ColorButton color="rgba(83,145,174,1)" />
      <ColorButton color="rgba(133,180,100,1)" />
      <ColorButton color="rgba(226,210,105,1)" />
      <ColorButton color="rgba(234,145,94,1)" />
      <ColorButton color="rgba(188,103,123,1)" />
      <ColorButton color="rgba(122,78,138,1)" />
    </div>
  );
}

export default ColorPalette;
