import React, { useContext } from "react";
import SudokuContext from "../SudokuContext";

function NumPadDigit({ number }) {
  const { sudoku, setSudoku, sudokuRef } = useContext(SudokuContext);

  function handleClick(event) {
    const key = number;
    const shift = event.shiftKey || sudoku.inputMode[1] ? true : false;
    const ctrl = event.ctrlKey || sudoku.inputMode[2] ? true : false;

    if (shift === true) {
      for (let row = 0; row < 9; row++) {
        for (let col = 0; col < 9; col++) {
          if (sudokuRef.current.grid[row][col].isSelected) {
            sudokuRef.current.grid[row][col].setCorner(
              Number(key),
              sudokuRef.current.isSetting
            );
          }
        }
      }
      // Center Numbers
    } else if (ctrl === true) {
      for (let row = 0; row < 9; row++) {
        for (let col = 0; col < 9; col++) {
          if (sudokuRef.current.grid[row][col].isSelected) {
            sudokuRef.current.grid[row][col].setCenter(
              Number(key),
              sudokuRef.current.isSetting
            );
          }
        }
      }
    } else {
      // State Number
      for (let row = 0; row < 9; row++) {
        for (let col = 0; col < 9; col++) {
          if (sudokuRef.current.grid[row][col].isSelected) {
            sudokuRef.current.grid[row][col].setValue(
              Number(key),
              sudokuRef.current.isSetting
            );
          }
        }
      }
    }
    sudokuRef.current.full2simple();
    setSudoku({ ...sudokuRef.current });
  }
  return (
    <div
      className="NumPad_Digit"
      onClick={(event) => {
        handleClick(event);
      }}
    >
      {number}
    </div>
  );
}

export default NumPadDigit;
