import React, { useContext, useState } from "react";
import SudokuContext from "../SudokuContext";
import useGrid from "../Hooks/useGrid";
import { createRandomSudoku } from "./Scripts/createRandomSudoku";
import { cloneDeep } from "lodash";
import { fullLogicSolve } from "../Solver/Scripts/additionalFunctions";
import { simple2fullgrid, full2simplegrid } from "./Scripts/simple2full";
import { simple2string } from "./Scripts/simple2string";

function AutoCreator() {
  const [newGrid, setNewGrid] = useGrid();
  const [createQuantity, setCreateQuantity] = useState(1);
  const [reducingCycles, setReducingCycles] = useState(1);

  function handleQuantityChange(event) {
    setCreateQuantity(event.target.value);
  }

  function handleReducingChange(event) {
    setReducingCycles(event.target.value);
  }

  function createAndSolve() {
    // Erstellt und löst ein zufälliges Sudoku
    // Je höher die Übergabe an createRandomSudoku, desto wahrscheinlicher ist
    // ein schweres Sudoku
    let isSolvable = false;
    let solveResults;
    let simpleGridBackup;

    while (!isSolvable) {
      console.log("STARTING GENERATION");
      let board = createRandomSudoku();
      let newGridClone = cloneDeep(newGrid);
      simpleGridBackup = cloneDeep(board);
      newGridClone = simple2fullgrid(board, newGridClone);
      solveResults = fullLogicSolve(newGridClone);
      isSolvable = solveResults.isSolved;
    }
    return solveResults;
  }

  function feederLoop() {
    // Erstellt mehrere Sudokus in einer Schleife, kategorisiert sie und
    // speichert sie in der SQL Datenbank ab
    for (let i = 0; i < createQuantity; i++) {
      let solveResults = createAndSolve();
      let difficulty;
      if (solveResults.score < 70) {
        difficulty = "easy";
      } else if (solveResults.score < 85) {
        difficulty = "medium";
      } else {
        difficulty = "hard";
      }
      let simpleGrid = full2simplegrid(solveResults.grid);
      let sudokuString = simple2string(simpleGrid);

      addSudoku(difficulty, sudokuString, solveResults.score);
      console.log("Sudoku created", difficulty);
    }
  }

  async function addSudoku(difficulty, sudoku, score) {
    const data = {
      difficulty: difficulty,
      sudoku: sudoku,
      score: score,
    };
    console.log(data.score);

    // Optionen für die HTTP-Anfrage
    const options = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    };

    // HTTP-Anfrage senden
    await fetch("/api/addSudoku.php", options)
      .then((response) => {
        return response.json();
      })
      // .then((data) => console.log(data))
      .catch((error) => console.error(error));
  }

  return (
    <div>
      <span>Reducing Cycles</span>
      <input
        type="number"
        value={reducingCycles}
        onChange={handleReducingChange}
      ></input>
      <span>Quantity</span>
      <input
        type="number"
        value={createQuantity}
        onChange={handleQuantityChange}
      ></input>
      <button
        onClick={() => {
          feederLoop();
        }}
      >
        Run Database Feeder
      </button>
    </div>
  );
}

export default AutoCreator;
