export function simple2string(simplegrid) {
  let sudokustring = "";
  // console.log(simplegrid);

  for (let row = 0; row < 9; row++) {
    for (let col = 0; col < 9; col++) {
      sudokustring += simplegrid[row][col];
    }
  }
  return sudokustring;
}
