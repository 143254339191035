import { getDependendGroups } from "./dependendGroups";

export function getNakedPairs(grid) {
  // Durchsucht alle Zellen der abhängigen Gruppen (Box, Col, Row)
  // Alle Zellen mit zwei Candidates werden verglichen und überprüft,
  // ob die Candidates identisch sind
  // Falls ja ist es ein Naked Pair und die Candidates werden aus den restlichen
  // Zellen entfernt
  // score = 3.0

  const dependenGroups = getDependendGroups(grid);
  let flag = false;

  for (const dependenGroup of dependenGroups) {
    // Alle Gruppen iterieren
    for (let i1 = 0; i1 < dependenGroup.length - 1; i1++) {
      // Referenzzelle index i1
      for (let i2 = i1 + 1; i2 < dependenGroup.length; i2++) {
        // Referenz wird mit dieser verglichen index i2
        if (
          dependenGroup[i1].candidates.length === 2 &&
          dependenGroup[i2].candidates.length === 2
        ) {
          // Länge = 2, es handelt sich demnach um pairs
          if (
            JSON.stringify(dependenGroup[i1].candidates) ===
            JSON.stringify(dependenGroup[i2].candidates)
          ) {
            // Pairs sind identisch
            for (
              let i_remove = 0;
              i_remove < dependenGroup.length;
              i_remove++
            ) {
              if (i_remove === i1 || i_remove === i2) {
                // Zellen mit pair werden übersprungen
                continue;
              } else {
                let tempFlag = dependenGroup[i_remove].removeCandidates(
                  dependenGroup[i1].candidates
                );

                if (tempFlag) {
                  flag = true;
                }
              }
            }
            if (flag) {
              return [grid, true, 3.0];
            }
          }
        }
      }
    }
  }

  return [grid, false, 0];
}
