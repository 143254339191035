export function getDependendGroups(grid) {
  // Gibt alle Gruppen als array zurück
  // Eine Gruppe ist zB. eine Box, Reihe, Spalte

  // [0-8] = Rows
  // [9-17] = Columns
  // [18-27] = Boxes

  const dependenGroups = [];

  // Rows
  for (let idx = 0; idx < 9; idx++) {
    let row = [];
    for (let col = 0; col < 9; col++) {
      row.push(grid[idx][col]);
    }
    dependenGroups.push(row);
  }

  // Columns
  for (let idx = 0; idx < 9; idx++) {
    let col = [];
    for (let row = 0; row < 9; row++) {
      col.push(grid[row][idx]);
    }
    dependenGroups.push(col);
  }

  // Boxes
  for (let colBlock = 0; colBlock < 9; colBlock = colBlock + 3) {
    for (let rowBlock = 0; rowBlock < 9; rowBlock = rowBlock + 3) {
      let box = [];
      for (let rowIdx = rowBlock; rowIdx < rowBlock + 3; rowIdx++) {
        for (let colIdx = colBlock; colIdx < colBlock + 3; colIdx++) {
          box.push(grid[rowIdx][colIdx]);
        }
      }
      dependenGroups.push(box);
    }
  }

  return dependenGroups;
}
