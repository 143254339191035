import { singleSolve } from "./singleSolve";
import { cloneDeep } from "lodash";
import { solutionCounter } from "./solutionCounter";
import { Sudoku } from "../../Backend/Sudoku";

export function createRandomSudoku() {
  const sudoku = new Sudoku();

  // // Einfaches Grid erstellen (Alles leer)
  const simpleGrid = Array(9)
    .fill(null)
    .map(() => Array(9).fill(0));

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  // Erste Zeile zufällig befüllen und lösen
  const firstLine = shuffleArray([1, 2, 3, 4, 5, 6, 7, 8, 9]);
  simpleGrid[0] = firstLine;
  const solvedSimple = singleSolve(simpleGrid);

  // Zahlen entfernen bis Lösungsanzahl == 1
  let board = solvedSimple;
  let backupBoard = board;
  let solutionCount = 1;

  let allIdxs = shuffleArray(getAllIdxs());

  while (allIdxs.length > 0) {
    // Ersten Index auswählen und aus Liste entfernen
    let currentIdx = allIdxs[0];
    allIdxs.shift();

    backupBoard = cloneDeep(board);
    board[currentIdx[0]][currentIdx[1]] = 0;

    solutionCount = solutionCounter(board);
    if (solutionCount > 1) {
      board = backupBoard;
    }
  }

  // const fullGrid = simple2fullgrid(board);
  return board;
}

function getAllIdxs() {
  const idxs = [];

  for (let i1 = 0; i1 < 9; i1++) {
    for (let i2 = 0; i2 < 9; i2++) {
      idxs.push([i1, i2]);
    }
  }
  return idxs;
}
