export function solutionCounter(grid) {
  function isValid(board, row, col, num) {
    for (let x = 0; x < 9; x++) {
      if (
        board[row][x] === num ||
        board[x][col] === num ||
        board[3 * Math.floor(row / 3) + Math.floor(x / 3)][
          3 * Math.floor(col / 3) + (x % 3)
        ] === num
      ) {
        return false;
      }
    }
    return true;
  }

  function findNextEmpty(board) {
    for (let row = 0; row < 9; row++) {
      for (let col = 0; col < 9; col++) {
        if (board[row][col] === 0) {
          return { row, col };
        }
      }
    }
    return null;
  }

  function countSudokuSolutions(board) {
    const emptySpot = findNextEmpty(board);
    if (!emptySpot) {
      return 1; // Keine leeren Plätze mehr, also eine gültige Lösung gefunden
    }

    let solutionCount = 0;
    for (let num = 1; num <= 9; num++) {
      if (isValid(board, emptySpot.row, emptySpot.col, num)) {
        board[emptySpot.row][emptySpot.col] = num;
        solutionCount += countSudokuSolutions(board);
        if (solutionCount > 1) {
          return 2;
        }
        board[emptySpot.row][emptySpot.col] = 0; // Zurücksetzen
      }
    }

    return solutionCount;
  }
  return countSudokuSolutions(grid);
}
