import React, { useState, useContext } from "react";
import "./Import.css";
import useGrid from "../Hooks/useGrid";
import SudokuContext from "../SudokuContext";
import { cloneDeep } from "lodash";

function Import() {
  const [inputSudoku, setInputSudoku] = useState("");
  const [newGrid, setNewGrid] = useGrid();
  const { setGrid } = useContext(SudokuContext);
  // const [clipboardContent, setClipboardContent] = useState("");

  const sample =
    "000000000008024010300000698052038960800060102000000030090000000006070000407100000";

  function handleInput(event) {
    setInputSudoku(event.target.value);
  }

  async function pasteCustomSudoku() {
    try {
      const sudokuString = await navigator.clipboard.readText();
      // setInputSudoku(sudokuString);
      setCustomGrid(sudokuString);
    } catch (err) {
      console.error("Fehler beim Lesen der Zwischenablage: ", err);
    }
  }

  function setCustomGrid(sudokuString_) {
    console.log("TEST");
    // setInputSudoku(sample);
    const copyGrid = cloneDeep(newGrid);
    if (sudokuString_.length === 81) {
      let index = 0;

      for (let row = 0; row <= 8; row++) {
        for (let col = 0; col <= 8; col++) {
          let value = sudokuString_[index];
          if (value != 0) {
            copyGrid[row][col].state = parseInt(value);
            copyGrid[row][col].isFixed = true;
          }
          index++;
        }
      }
      setGrid(copyGrid);
    } else {
      console.log("STRING IN WRONG FORMAT");
    }
  }
  return (
    <div className="Import__container">
      {/* <input type="text" value={inputSudoku} onChange={handleInput} /> */}
      {/* <button onClick={setCustomGrid}>Set Grid</button> */}
      <button onClick={pasteCustomSudoku}>Paste Sudoku</button>
    </div>
  );
}

export default Import;
