import { getDependendGroups } from "./dependendGroups";

export function getHiddenSingles(grid) {
  // Hidden Single bedeutet, dass eine Zahl nur noch in einer Zelle (dependenGroup)
  // vorkommen kann. In dieser Zelle sind jedoch auch andere Zahlen möglich

  // SCORE:
  // Hidden Single als letzte Zahl:     1
  // Hidden Single in Block:            1.2
  // Hidden Single in row col:          1.5

  const dependendGroups = getDependendGroups(grid);
  let flag = false;
  let scoreCounter = 0;

  let groupIndex = 0;
  for (const dependendGroup of dependendGroups) {
    for (let number = 1; number <= 9; number++) {
      let counter = 0;
      for (let index = 0; index < 9; index++) {
        if (dependendGroup[index].candidates.includes(number)) {
          counter++;
        }
      }
      if (counter === 1) {
        // Hidden Single gefunden
        flag = true;
        scoreCounter++;
        let lastSpotCounter = 0;
        for (let index = 0; index < 9; index++) {
          if (dependendGroup[index].candidates.includes(number)) {
            dependendGroup[index].state = number;
            dependendGroup[index].candidates = [];
          }
          // Überprüfen, ob nur noch ein Spot frei ist
          if (dependendGroup[index].state === null) {
            lastSpotCounter++;
          }
        }
        // Ist HS der letzte Platz in Gruppe?
        const lastSpot = lastSpotCounter > 1 ? false : true;
        // Ist HS in einer Box?
        const inBox = groupIndex > 18 ? true : false;

        let score;

        if (lastSpot) {
          score = 1;
        } else if (inBox) {
          score = 1.2;
        } else {
          score = 1.5;
        }
        return [grid, true, score];
      }
    }
    groupIndex++;
  }

  return [grid, false, 0];
}
