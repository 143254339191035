import React from "react";
import "./CustomPage.css";
import { Helmet } from "react-helmet";
import Sudoku_Custom from "../../Sudoku_Components/Sudoku_Apps/Sudoku_Custom";

function CustomPage() {
  return (
    <div className="CustomPage__container">
      <MetaTags />
      <div className="CustomPage_SudokuApp">
        <Sudoku_Custom />
      </div>
      <h1>Create your custom Sudokus</h1>
      <span>
        All numbers set under 'Setting' are fixed and cannot be altered during
        the Sudoku-solving process. In essence, 'Setting' allows you to create
        the Sudoku puzzle by placing the initial numbers. It is advisable to
        input all given numbers first and then use color coding for better
        visibility. Once all numbers are in place, switch to 'Solving' mode and
        embark on solving the Sudoku puzzle. Enjoy the Sudoku-Hub builder, and
        have fun!
      </span>
    </div>
  );
}

function MetaTags() {
  return (
    <Helmet>
      <title>Create your own Sudoku</title>
      <meta
        name="description"
        content="Discover the versatility of Sudoku on our platform, where users can effortlessly 
        create and play their own puzzles. With a user-friendly interface, enjoy the freedom to 
        design and solve personalized Sudoku challenges at your own pace. Explore the interactive 
        features of our website, offering a neutral and customizable gaming experience. 
        Start crafting and playing your unique Sudokus today!"
      />
    </Helmet>
  );
}

export default CustomPage;
