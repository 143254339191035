import { cloneDeep } from "lodash";
// import useGrid from "../Hooks/useGrid";
// import SudokuContext from "../SudokuContext";

export function string2grid(sudokuString_, newGrid) {
  // const [newGrid, setNewGrid] = useGrid();
  const copyGrid = cloneDeep(newGrid);
  if (sudokuString_.length === 81) {
    let index = 0;

    for (let row = 0; row <= 8; row++) {
      for (let col = 0; col <= 8; col++) {
        let value = sudokuString_[index];
        if (value != 0) {
          copyGrid[row][col].state = parseInt(value);
          copyGrid[row][col].isFixed = true;
          copyGrid[row][col].color = "rgba(230,230,230,1)";
        }
        index++;
      }
    }
  }
  return copyGrid;
}
