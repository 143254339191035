export class Cell {
  constructor(index) {
    this.index = index;
    this.box = [parseInt(index[0] / 3), parseInt(index[1] / 3)];
    this.value = null;
    this.corner = [];
    this.center = [];
    this.candidates = [];
    this.color = "rgba(255,255,255,1)";
    this.isLocked = false;
    this.isError = false;
    this.isSelected = false;
  }
  setValue(number_, setting_) {
    if (setting_ === true) {
      this.isLocked = true;
      this.value = number_;
    } else if (setting_ === false && this.isLocked === false) {
      this.value = number_;
    }
  }
  setCorner(number_, setting_) {
    if (setting_ === true) {
      this.isLocked = true;
      this.corner = this.addOrRemoveNumber(number_, this.corner, 4);
    } else if (setting_ === false && this.isLocked === false) {
      this.corner = this.addOrRemoveNumber(number_, this.corner, 4);
    }
  }
  setCenter(number_, setting_) {
    if (setting_ === true) {
      this.isLocked = true;
      this.center = this.addOrRemoveNumber(number_, this.center, 9);
    } else if (setting_ === false && this.isLocked === false) {
      this.center = this.addOrRemoveNumber(number_, this.center, 9);
    }
  }
  setColor(color_) {
    this.color = color_;
  }
  clearAll(setting_) {
    if (setting_ === true) {
      this.value = null;
      this.center = [];
      this.corner = [];
      this.isLocked = false;
    } else if (setting_ === false && this.isLocked === false) {
      this.value = null;
      this.center = [];
      this.corner = [];
    }
  }

  addOrRemoveNumber(number, array, maxAmount) {
    const index = array.indexOf(number);

    if (index === -1) {
      if (array.length < maxAmount) {
        array.push(number);
      }
    } else {
      array.splice(index, 1);
    }
    const sortedNumbers = array.sort((a, b) => a - b);
    return sortedNumbers;
  }

  candidates2center() {
    this.center = this.candidates;
  }

  removeCandidates(antiCandidates) {
    // Länge vor und nach add wird verglichen,
    // um zu schauen, ob die anitCandidates neu waren
    const preRemoveLength = this.candidates.length;
    for (const antiCandidate of antiCandidates) {
      for (let idx = 0; idx < this.candidates.length; idx++) {
        if (antiCandidate === this.candidates[idx]) {
          this.candidates.splice(idx, 1);
        }
      }
    }
    this.candidates2center();

    const postRemoveLength = this.candidates.length;
    if (preRemoveLength > postRemoveLength) {
      return true;
    } else {
      return false;
    }
  }
}
