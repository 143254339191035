export function simple2fullgrid(_simplegrid, newGrid) {
  for (let row = 0; row < 9; row++) {
    for (let col = 0; col < 9; col++) {
      let value = _simplegrid[row][col];
      if (value != 0) {
        newGrid[row][col].state = value;
        newGrid[row][col].isFixed = true;
      }
    }
  }
  return newGrid;
}

export function full2simplegrid(newGrid) {
  const simpleGrid = Array(9)
    .fill(null)
    .map(() => Array(9).fill(0));

  for (let row = 0; row < 9; row++) {
    for (let col = 0; col < 9; col++) {
      if (newGrid[row][col].state != null) {
        simpleGrid[row][col] = newGrid[row][col].state;
      }
    }
  }

  return simpleGrid;
}
