import { getDependendGroups } from "../Solver/Scripts/dependendGroups";
import { isValidSudokuString } from "../GlobalScripts/isValidSudokuString";
import { Cell } from "./Cell";
import { Solver } from "./Solver";

export class Sudoku {
  constructor() {
    this.grid = null;
    this.simplegrid = null;
    this.simplegridUnsolved = null;
    this.string = null;
    this.stringUnsolved = null;
    this.selectionGrid = null;
    this.isSetting = false;
    this.isFinished = false;
    this.isSolvable = false;
    this.inputMode = [true, false, false];
    this.score = false;
    this.dependendGroups = [];

    this.initializeSudoku();
  }

  initializeSudoku() {
    this.grid = Array(9)
      .fill(null)
      .map(() => Array(9).fill(null));
    for (let idx_col = 0; idx_col < 9; idx_col++) {
      for (let idx_row = 0; idx_row < 9; idx_row++) {
        this.grid[idx_col][idx_row] = new Cell([idx_col, idx_row]);
      }
    }
    this.dependendGroups = getDependendGroups(this.grid);

    this.simplegrid = Array(9)
      .fill(null)
      .map(() => Array(9).fill(0));

    this.selectionGrid = Array(9)
      .fill(null)
      .map(() => Array(9).fill(false));
  }

  resetSelection() {
    for (let row = 0; row < 9; row++) {
      for (let col = 0; col < 9; col++) {
        if (this.grid[row][col].isSelected) {
          this.grid[row][col].isSelected = false;
        }
      }
    }
  }

  stringUnsolved2full() {
    // String to simplegrid and grid
    this.initializeSudoku();
    let idxString = 0;
    for (let row = 0; row < 9; row++) {
      for (let col = 0; col < 9; col++) {
        let value = this.stringUnsolved[idxString];
        this.simplegrid[row][col] = value;
        if (value != 0) {
          this.grid[row][col].value = parseInt(value);
          this.grid[row][col].isLocked = true;
          this.grid[row][col].color = "rgba(230,230,230,1)";
        }
        idxString++;
      }
    }
  }

  setStringUnsolved(string_) {
    if (isValidSudokuString(string_)) {
      this.stringUnsolved = string_;
    }
  }

  checkForError() {
    // Überprüft, ob ein Fehler im Sudoku ist
    // Alle isError zurücksetzen
    for (let row = 0; row < 9; row++) {
      for (let col = 0; col < 9; col++) {
        if (this.grid[row][col].isError) {
          this.grid[row][col].isError = false;
        }
      }
    }
    // Nach Fehlern suchen und isError setzen
    for (const dependendGroup of this.dependendGroups) {
      for (let i1 = 0; i1 < 8; i1++) {
        for (let i2 = i1 + 1; i2 < 9; i2++) {
          if (
            dependendGroup[i1].value === dependendGroup[i2].value &&
            dependendGroup[i1].value != null
          ) {
            dependendGroup[i1].isError = true;
            dependendGroup[i2].isError = true;
          }
        }
      }
    }
  }

  full2simple() {
    // Überträgt das fullgrid zu simplegrid
    for (let row = 0; row < 9; row++) {
      for (let col = 0; col < 9; col++) {
        if (this.grid[row][col].value != null) {
          this.simplegrid[row][col] = this.grid[row][col].value;
        } else {
          this.simplegrid[row][col] = 0;
        }
      }
    }
    this.checkForError();
  }

  simple2full() {
    // Überträgt das simplegrid zu fullgrid
    for (let row = 0; row < 9; row++) {
      for (let col = 0; col < 9; col++) {
        if (this.simplegrid[row][col] != 0) {
          this.grid[row][col].value = this.simplegrid[row][col];
        }
      }
    }
  }
}
