export function getXWing(grid) {
  let flag = false;

  // Reihen nach Pairs durchsuchen
  for (let number = 1; number <= 9; number++) {
    // Alle Pairs, welche in Reihen gefunden werden und nicht in der gleichen Box sind werden
    // in rowPairs gespeichert
    let rowPairs = [];
    for (let row = 0; row < 9; row++) {
      let numberCounter = 0;
      let pairCells = [];
      for (let col = 0; col < 9; col++) {
        if (grid[row][col].candidates.includes(number)) {
          numberCounter++;
          pairCells.push(grid[row][col]);
        }
      }
      if (numberCounter === 2 && notInSameBox(pairCells)) {
        rowPairs.push(pairCells);
      }
    }
    // rowPairs überprüfen, ob es ein Paar gibt, welches in den gleichen Spalten liegt
    // Paar darf außerdem nicht in der gleichen Box liegen
    if (rowPairs.length >= 2) {
      for (
        let firstPairIdx = 0;
        firstPairIdx < rowPairs.length - 1;
        firstPairIdx++
      ) {
        // firstPair ist das erste Paar, es wird mit allen weiteren in firstPair verglichen
        for (
          let secondPairIdx = firstPairIdx + 1;
          secondPairIdx < rowPairs.length;
          secondPairIdx++
        ) {
          let firstPair = rowPairs[firstPairIdx];
          let secondPair = rowPairs[secondPairIdx];
          // Überprüfen, ob sie in der gleichen Spalte liegen und nicht in der gleichen Box sind
          if (
            firstPair[0].index[1] === secondPair[0].index[1] &&
            firstPair[1].index[1] === secondPair[1].index[1] &&
            notInSameBox([firstPair[0], secondPair[0]])
          ) {
            // console.log("X-Wing gefunden");
            // console.log(firstPair, secondPair);
            // number in allen restlichen Zellen eliminieren
            // in blockedRows liegt der X-Wing, hier werden keine candidates eliminiert
            const blockedRows = [firstPair[0].index[0], secondPair[0].index[0]];
            const xWingColumns = [firstPair[0].index[1], firstPair[1].index[1]];
            for (let row = 0; row < 9; row++) {
              if (!blockedRows.includes(row)) {
                grid[row][xWingColumns[0]].removeCandidates([number]);
                grid[row][xWingColumns[1]].removeCandidates([number]);
              }
            }
          }
        }
      }
    }
  }
  return [grid, flag];
}

function notInSameBox(pairCells) {
  if (JSON.stringify(pairCells[0].box) === JSON.stringify(pairCells[1].box)) {
    return false;
  } else {
    return true;
  }
}
