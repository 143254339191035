import React, { useState } from "react";

function useSelection() {
  // Cell Selection
  const initialSelectionGrid = Array(9)
    .fill(null)
    .map(() => Array(9).fill(false));

  const [selectionGrid, setSelectionGrid] = useState(initialSelectionGrid);
  return [selectionGrid, setSelectionGrid];
}

export default useSelection;
