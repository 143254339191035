import React, { useState } from "react";
import "./Header.css";
import Logo from "../Images/logo_minified.svg";
import MenuBar from "../Images/menu-icon.png";
import useViewportWidth from "../Sudoku_Components/Hooks/useViewport";

function Header() {
  const width = useViewportWidth();

  function MenuItems() {
    return (
      <ul id="NoBulletPoints">
        <li>
          <a href="/">Preset</a>
        </li>
        <li>
          <a href="/Custom">Custom</a>
        </li>
        <li>
          <a href="/HowTo">How To Play</a>
        </li>
        {/* <li>
          <a href="/Solver">Solver</a>
        </li> */}
      </ul>
    );
  }

  function DesktopHeader() {
    return (
      <div className="Header_Desktop__container">
        <div className="Header_Desktop__content">
          <a href="/">
            <img src={Logo} height={40} alt={"Sudoku-Hub Logo"} />
          </a>
          <MenuItems />
        </div>
      </div>
    );
  }

  function MobileHeader() {
    const [isDropdown, setDropdown] = useState(false);

    function DropdownMenu() {
      return (
        <div className="Header_Mobile_Dropdown">
          <MenuItems />
        </div>
      );
    }

    return (
      <div className="Header_Mobile__container">
        <div className="Header_Mobile_Bar">
          <a href="/">
            <img src={Logo} height={35} alt={"Sudoku-Hub Logo"} />
          </a>
          <img
            src={MenuBar}
            height={35}
            alt={"Menu Bar"}
            className="Header_Mobile_MenuBar"
            onClick={() => {
              setDropdown(!isDropdown);
            }}
          />
        </div>
        {isDropdown ? <DropdownMenu /> : <></>}
      </div>
    );
  }
  return width < 600 ? <MobileHeader /> : <DesktopHeader />;
}

export default Header;
