import React, { useContext, useEffect, useState } from "react";
import "./Database.css";
import SudokuContext from "../SudokuContext";
import useGrid from "../Hooks/useGrid";
// import { string2grid } from "../GlobalScripts/string2grid";
import Stopwatchcounter from "./Stopwatchcounter";

function Database() {
  const { setSudoku, sudokuRef } = useContext(SudokuContext);
  const [counterReset, setCounterReset] = useState(false);
  const sampleString =
    "600000000000008509570300004003450700000007001080000006020600000000030000960010080";

  // SQL FUNCTION
  async function getSQLSudoku(grade_) {
    try {
      const response = await fetch("/api/loadSudoku" + grade_ + ".php");
      const data = await response.json();
      return data[0].sudoku_string;
    } catch (error) {
      console.log(error);
      return sampleString;
    }
  }

  useEffect(() => {
    loadSudoku("medium");
  }, []);

  async function loadSudoku(grade_) {
    setCounterReset(true);
    // const sudokuString = await getSQLSudoku(grade_);
    getSQLSudoku(grade_).then((sudokuString) => {
      sudokuRef.current.stringUnsolved = sudokuString;
      sudokuRef.current.stringUnsolved2full();
      setSudoku({ ...sudokuRef.current });
    });
  }

  return (
    <div className="Database__container">
      <button
        className="Database_Mode"
        id="FirstLetter"
        onClick={() => {
          loadSudoku("easy");
        }}
      >
        Easy
      </button>
      <button
        className="Database_Mode"
        id="FirstLetter"
        onClick={() => {
          loadSudoku("medium");
        }}
      >
        Medium
      </button>
      <button
        className="Database_Mode"
        id="FirstLetter"
        onClick={() => {
          loadSudoku("hard");
        }}
      >
        Hard
      </button>
      <div className="Database_Timer">
        <Stopwatchcounter
          counterReset={counterReset}
          setCounterReset={setCounterReset}
        />
      </div>
    </div>
  );
}

export default Database;
