export function getFreeCells(grid) {
  let counter = 0;
  for (let row = 0; row < 9; row++) {
    for (let col = 0; col < 9; col++) {
      if (grid[row][col].state === null) {
        counter++;
      }
    }
  }
  return counter;
}
