import React from "react";
import "./SolverPage.css";
import Sudoku_Solver from "../../Sudoku_Components/Sudoku_Apps/Sudoku_Solver";

function SolverPage() {
  return (
    <div className="SolverPage__container">
      <div className="SolverPage_context">
        <h1>Sudoku Solver</h1>
        <span>
          The app on this page is used to solve your Sudoku. If you get stuck on
          a puzzle or just want to make sure that it has a solution you can type
          it in the solver and check the solution. Have fun with the Solver!
        </span>
      </div>

      <Sudoku_Solver />
    </div>
  );
}

export default SolverPage;
