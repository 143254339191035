import React, { useState, useContext } from "react";
import "./NumPad.css";
import NumPadBar from "./NumPadBar";
import NumPadDigit from "./NumPadDigit";
import NumPadPopup from "./NumPadPopup";
import SudokuContext from "../SudokuContext";
import useViewportWidth from "../Hooks/useViewport";

function NumPad() {
  const Digits = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const { setSudoku, sudokuRef } = useContext(SudokuContext);
  const viewportWidth = useViewportWidth();

  function handleDelete() {
    for (let row = 0; row < 9; row++) {
      for (let col = 0; col < 9; col++) {
        if (sudokuRef.current.grid[row][col].isSelected) {
          sudokuRef.current.grid[row][col].clearAll(
            sudokuRef.current.isSetting
          );
        }
      }
    }
    sudokuRef.current.full2simple();
    setSudoku({ ...sudokuRef.current });
  }

  function NumPadDesktop() {
    return (
      <div className="NumPad__container">
        {/* <NumPadPopup /> */}
        <NumPadBar />
        <div className="NumPad_Grid">
          <div className="NumPad_Grid_Numbers">
            {Digits.map((digit, index) => (
              <NumPadDigit key={index} number={digit} />
            ))}
          </div>
          <div className="NumPad_Grid_Delete">
            <button onClick={handleDelete}>X</button>
          </div>
        </div>
      </div>
    );
  }

  function NumPadMobile() {
    return (
      <div className="NumPad__container">
        <NumPadBar />
        <div className="NumPad_Grid">
          <div className="NumPad_Grid_Numbers">
            {Digits.map((digit, index) => (
              <NumPadDigit key={index} number={digit} />
            ))}
            <div className="NumPad_Grid_Delete" onClick={handleDelete}>
              <button>X</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <>{viewportWidth < 1040 ? <NumPadMobile /> : <NumPadDesktop />}</>;
}

export default NumPad;
