export class Solver {
  constructor(sudoku_) {
    this.sudoku = sudoku_;
    this.score = 0;
    this.finished = false;
  }

  backtrackSolve() {
    const isValid = (row, col, num) => {
      for (let x = 0; x < 9; x++) {
        if (
          this.sudoku.simplegrid[row][x] === num ||
          this.sudoku.simplegrid[x][col] === num ||
          this.sudoku.simplegrid[3 * Math.floor(row / 3) + Math.floor(x / 3)][
            3 * Math.floor(col / 3) + (x % 3)
          ] === num
        ) {
          return false;
        }
      }
      return true;
    };

    const solve = () => {
      for (let row = 0; row < 9; row++) {
        for (let col = 0; col < 9; col++) {
          if (this.sudoku.simplegrid[row][col] === 0) {
            for (let num = 1; num <= 9; num++) {
              if (isValid(row, col, num)) {
                this.sudoku.simplegrid[row][col] = num;

                if (solve()) {
                  return true;
                }

                this.sudoku.simplegrid[row][col] = 0;
              }
            }
            return false;
          }
        }
      }
      return true;
    };

    solve();
    this.sudoku.simple2full();
  }
}
