import { getDependendGroups } from "./dependendGroups";

export function getHiddenPairs(grid) {
  const dependendGroups = getDependendGroups(grid);
  let flag = false;

  // Es werden alle Zahlen überprüft, ob sie nur 2x in der Gruppe vorkommen
  // Falls sie nur 2x vorkommen werden sie in pairIndex gespeichert
  // Am Ende wird pairIndex überprüft, ob ein pair die gleichen Indexpositionen hat
  // Falls ja handelt es sich um ein hiddenPair
  // Aus den Zellen mit dem Hidden Pair werden alle weiteren Candidates entfernt
  // stepScore = 2.0

  for (const dependendGroup of dependendGroups) {
    const pairs = [];
    for (let number = 1; number <= 9; number++) {
      // Alle Nummern iterieren
      let numberCounter = 0;
      let localPairs = [];
      for (let index = 0; index < 9; index++) {
        if (dependendGroup[index].candidates.includes(number)) {
          numberCounter++;
          localPairs.push(index);
        }
      }
      if (numberCounter === 2) {
        // Es handelt sich um ein pair
        pairs.push([number, localPairs]);
      }
    }
    // Überprüfen, ob Zwei Pairs die selben Index haben
    if (pairs.length > 1) {
      for (let i1 = 0; i1 < pairs.length - 1; i1++) {
        for (let i2 = i1 + 1; i2 < pairs.length; i2++) {
          if (JSON.stringify(pairs[i1][1]) === JSON.stringify(pairs[i2][1])) {
            // Es handelt sich um ein doppeltes Paar
            // Alle weiteren Candidates aus diesen Zellen entfernen
            const numbers = [pairs[i1][0], pairs[i2][0]];
            const cells = pairs[i1][1];

            // AntiCandidates erzeugen
            const antiCandidates = [];
            for (let i = 1; i < 10; i++) {
              if (i !== numbers[0] && i !== numbers[1]) {
                antiCandidates.push(i);
              }
            }

            // Candidates aus Zelle entfernen
            let localFlags = [];
            localFlags.push(
              dependendGroup[cells[0]].removeCandidates(antiCandidates)
            );
            localFlags.push(
              dependendGroup[cells[1]].removeCandidates(antiCandidates)
            );

            if (localFlags.includes(true)) {
              return [grid, true, 2.0];
            }
          }
        }
      }
    }
  }

  return [grid, false, 0];
}
