import React, { useState, useContext } from "react";
import SudokuContext from "../SudokuContext";

function NumPadBar() {
  const { sudoku, setSudoku, sudokuRef } = useContext(SudokuContext);
  const primaryRGB = "rgba(36,105,69,1)";
  const blackRGB = "rgba(0,0,0,1)";
  const whiteRGB = "rgba(255,255,255,1)";

  const selectedDiv = { backgroundColor: primaryRGB, color: whiteRGB };
  const notSelectedDiv = { backgroundColor: whiteRGB, color: blackRGB };

  function handleModeSwitch(mode) {
    switch (mode) {
      case "number":
        sudokuRef.current.inputMode = [true, false, false];
        break;
      case "corner":
        sudokuRef.current.inputMode = [false, true, false];
        break;
      case "center":
        sudokuRef.current.inputMode = [false, false, true];
        break;
    }
    setSudoku({ ...sudokuRef.current });
  }

  return (
    <div className="NumPadBar">
      <button
        id="NumPadBar_Number"
        onClick={() => {
          handleModeSwitch("number");
        }}
        style={sudoku.inputMode[0] ? selectedDiv : notSelectedDiv}
      >
        Number
      </button>
      <button
        id="NumPadBar_Corner"
        onClick={() => {
          handleModeSwitch("corner");
        }}
        style={sudoku.inputMode[1] ? selectedDiv : notSelectedDiv}
      >
        Corner
      </button>
      <button
        id="NumPadBar_Center"
        onClick={() => {
          handleModeSwitch("center");
        }}
        style={sudoku.inputMode[2] ? selectedDiv : notSelectedDiv}
      >
        Center
      </button>
    </div>
  );
}

export default NumPadBar;
