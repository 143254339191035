import React, { useContext } from "react";
import "./SettingBar.css";
import SudokuContext from "../SudokuContext";

function SettingBar() {
  const { sudoku, setSudoku, sudokuRef } = useContext(SudokuContext);

  const primaryRGB = "rgba(36,105,69,1)";
  const blackRGB = "rgba(0,0,0,1)";
  const whiteRGB = "rgba(255,255,255,1)";

  const selectedDiv = { backgroundColor: primaryRGB, color: whiteRGB };
  const notSelectedDiv = { backgroundColor: whiteRGB, color: blackRGB };

  function switchSetting(setting_) {
    if (sudokuRef.current.isSetting != setting_) {
      sudokuRef.current.isSetting = setting_;
      setSudoku({ ...sudokuRef.current });
    }
  }

  return (
    <div className="SettingBar__container">
      <button
        id="SettingBar_Setting"
        onClick={() => {
          switchSetting(true);
        }}
        style={sudoku.isSetting ? selectedDiv : notSelectedDiv}
      >
        Setting
      </button>
      <button
        id="SettingBar_Solving"
        onClick={() => {
          switchSetting(false);
        }}
        style={sudoku.isSetting ? notSelectedDiv : selectedDiv}
      >
        Solving
      </button>
    </div>
  );
}

export default SettingBar;
