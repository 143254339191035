import { getDependendGroups } from "./dependendGroups";

export function getDirectClaiming(grid) {
  // Direct Claiming bedeutet, dass in einer Zeile eine Zahl nur in einer
  // Box vorkommen kann
  // https://wordsup.co.uk/sudoku/direct-claiming

  //   Nur Reihen und Spalten
  const dependendGroups = getDependendGroups(grid).slice(0, 17);
  const dependendBoxes = getDependendGroups(grid).slice(-9);

  let removeFlag = false;

  for (const dependendGroup of dependendGroups) {
    for (let number = 0; number <= 9; number++) {
      // Durch alle Nummern iterieren
      let refBox = false;
      let flag = false;
      let DCcells = [];
      for (let index = 0; index < 9; index++) {
        // Durch die Zellen der Gruppe iterieren
        if (dependendGroup[index].candidates.includes(number)) {
          if (!refBox) {
            // Referenzbox definieren
            // Alle folgenden Zellen müssen in der gleichen Box sein
            refBox = JSON.stringify(dependendGroup[index].box);
            DCcells.push(JSON.stringify(dependendGroup[index].index));
          }
          //   Überprüfen, ob es in der gleichen Box ist
          if (JSON.stringify(dependendGroup[index].box) != refBox) {
            // nicht in gleicher Box -> kein Direct Claiming
            // Nächste Number überprüfen
            break;
          } else {
            // In gleicher Box
            DCcells.push(JSON.stringify(dependendGroup[index].index));
          }
        }
      }
      for (const dependendBox in dependendBoxes) {
        if (JSON.stringify(dependendBox[0].box) === refBox) {
          // Richtige Box
          for (let i = 0; i < 9; i++) {
            if (!isDCcell(JSON.stringify(dependendBox[i].index), DCcells)) {
              removeFlag = dependendBox[i].removeCandidates([number]);
            }
          }
          return [grid, true, 1.9];
        }
      }
    }
  }
  return [grid, false, 0];
}

function isDCcell(index_, DCcells_) {
  // Überprüft, ob der Index zu den DCcells gehört
  let flag = false;
  for (const DCcell in DCcells_) {
    if (index_ === DCcell) {
      // Ist eine DR Zelle
      flag = true;
    }
  }
  return flag;
}
